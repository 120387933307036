import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work Experience"
				body={
					<div className="works-body">
						<div className="work">
							<img
								 style={{backgroundColor: '#0b38a0'}}
								src="./Kelpie Logo.jpg"
								alt="kelpie"
								className="work-image"
							/>
							<div className="work-title">Full-Stack Developer</div>
							<div className="work-subtitle">
							 <a href="https://kelpie.solutions/" target="_blank" style={{ color: "#0f8b7e", textDecoration:"none", cursor: "pointer"}}>Kelpie Solutions</a>	
							</div>
							<div className="work-duration">2021 - 2024</div>
						</div>

					</div>
				}
			/>
		</div>
	);
};

export default Works;
