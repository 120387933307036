const INFO = {
	main: {
		title: "Diego Ambeliz Portfolio",
		name: "Diego Ambeliz",
		email: "",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/diegoambeliz",
		github: "https://github.com/diegoambeliz",
		linkedin: "https://linkedin.com/in/diegoambeliz",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-Stack Developer",
		description:
			"Hi everyone! 🤓 I’m Diego Ambeliz, a full-stack developer with over 3 years of experience working with technologies like Next.js, React, Angular, Node.js, and C#. I’ve developed and delivered several web and mobile applications for various companies and built user-focused apps. I’m passionate about solving problems and pride myself on having strong communication skills. 🧑🏻‍💻🚀",
	},

	about: {
		title: "Hi, I’m Diego Ambeliz – Your Go-To Developer",
		description:
			"My name is Diego Ambeliz, a tech enthusiast passionate about software development. I started coding at 13, creating my first websites, and have been learning and practicing consistently ever since. I've worked as a Full-Stack Developer in a software company, contributing to a wide range of projects for various clients, including businesses with hundreds of users. Additionally, I have freelance experience, delivering remote projects and solving bugs for individual clients and companies alike.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Url Shortener (Frontend)",
			description:
				"A powerful URL shortener built with Angular that enables users to register, verify email addresses, authenticate, and manage their own shortened links. Users can also track link click counts and other detailed analytics",
			logo: "https://miro.medium.com/v2/resize:fit:1400/0*35Va_iGmAnYdQd_F.png",
			linkText: "View Project",
			link: "https://github.com/diegoambeliz/url-shortener-frontend",
		},
		{
			title: "Url Shortener (Backend)",
			description:
				"A robust backend service for a URL shortener application, developed using C# and .NET ASP.NET Core with SQL Server. It supports user registration, email verification, authentication, URL shortening, and detailed link management with click count tracking.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png",
			linkText: "View Project",
			link: "https://github.com/diegoambeliz/url-shortener-backend",
		},
		{
			title: "Todo App (Frontend)",
			description:
				"Developed using Next.js, the frontend offers a responsive and user-friendly interface for managing tasks. Users can easily create, update, delete, and categorize their tasks, ensuring an intuitive and efficient user experience.",
			logo: "https://cdn.worldvectorlogo.com/logos/next-js.svg",
			linkText: "View Project",
			link: "https://github.com/diegoambeliz/todoapp-frontend",
		},

		{
			title: "Todo App (Backend)",
			description:
				"Built with C# ASP.NET and SQL Server, the backend provides robust and secure data management. It handles user authentication, task operations, and database interactions, ensuring smooth and reliable performance.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png",
			linkText: "View Project",
			link: "https://github.com/diegoambeliz/todoapp-backend",
		}
		// {
		// 	title: "Project 2",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 3",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
